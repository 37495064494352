.full-page {
  height: 90vh;
}

.auth-card {
  margin: auto;
  max-width: 36rem;
}

.width-20 {
  width: 20rem;
}

.hint {
  font-size: small;
}

.request-status {
  text-transform: capitalize;
}

.bottom-card {
  width: 18rem;
  margin: auto;
  min-height: 20rem;
}

.request-card {
  margin: auto;
  max-width: 36rem;
  width: -webkit-fill-available;
}

.profile-picture {
  height: 60px;
  width: 60px;
  padding: 2px;
}

.logo {
  width: 80%;
  max-width: 220px;
}

.logo-auth {
  max-width: 350px;
  margin-bottom: 20px;
}

.toast-background {
  background: red !important;
  color: white !important;
  font-weight: bold;
}

.toast-progress {
  background: white !important;
}

.dialog {
  display: block !important;
  top: 33% !important;
}

* {
  font-family: "Montserrat", sans-serif;
}
.shape {
  height: auto;
  width: 100%;
  background-color: black;
  background-size: cover;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.status {
  background-color: lavenderblush;
  font-weight: bold;
}
.btn {
  font-family: "Montserrat", sans-serif !important;
}

p {
  font-family: Montserrat;
  font-size: x-small;
}
nav a {
  font-family: Montserrat;
}

.nav-link {
  font-family: Montserrat;
  color: white !important;
  font-weight: bold;
}
.nav-link:hover {
  text-shadow: 0 0 1px white;
}
.card-text {
  font-size: medium;
}

.curved {
  background-color: #1f1e54;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  width: 200%;
  height: 3rem;
  background-image: url();
}

.number-input {
  font-family: Montserrat;
  border: none;
  border-bottom: 1px solid gray;
  padding: 5px;
  background-color: transparent;
}

.label {
  border: 1px solid black;
  border-radius: 0.25rem !important;
  background-color: transparent;
  font-family: "Montserrat";
  padding: 5px;
  margin: 5px;
  cursor: pointer;
}

.profile-name {
  font-size: medium;
  font-weight: bold;
}
/* background-color: #000;
  bottom: 0;
  color: #fff;
  left: 0;
  opacity: 0.5;
  position: absolute;
  width: 100%; */

.fix {
  width: 100%;
  padding: 0px;
}

.profile-sub {
  font-size: medium;
}

.border-validator {
  border-right: 5px solid #dee2e6 !important;
}

.cursor-pointer {
  cursor: pointer;
}

/* Navbar */
.close-form {
  margin-left: 95%;
  margin-top: 20px;
}
